"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _goodReceivedNote = require("@/api/goodReceivedNote");

var _elementUi = require("element-ui");

var _supplier = require("@/api/supplier");

var _errorParse = require("@/utils/error-parse");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _lodash = _interopRequireDefault(require("lodash.debounce"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    // vSelect,
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        perPage: 10,
        listAll: true,
        status: null,
        targetRestockDate: null,
        stores: null
      },
      total: 0,
      supplierName: null,
      supplierSelectedName: null,
      hash: null,
      statusByOptions: [{
        key: 'drafted',
        name: 'Drafted'
      }, {
        key: 'confirmed',
        name: 'Confirmed'
      }]
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['user'])
  },
  watch: {
    '$i18n.locale'() {
      // Perform the readjustment here
      this.adjustTabs();
    }

  },

  async created() {
    this.hash = Math.floor(Math.random() * 100) + 1;

    if (this.$route.params.pathMatch) {
      // this.activeName = this.$route.params.pathMatch
      this.handleTabClick({
        name: this.$route.params.pathMatch
      });
    } else {
      // this.activeName = 'all'
      this.handleTabClick({
        name: 'all'
      });
    }
  },

  methods: {
    async fetchData(targetTabName = undefined) {
      if (targetTabName !== undefined) {
        let targetStatus = null;

        if (targetTabName === 'all') {
          targetStatus = null;
        } else if (targetTabName === 'drafted') {
          targetStatus = 'Drafted';
        } else if (targetTabName === 'confirmed') {
          targetStatus = 'Confirmed';
        } else if (targetTabName === 'completed') {
          targetStatus = 'Completed';
        } else {
          targetStatus = null;
        }

        this.listQuery.status = targetStatus;
      }
      /* if (!this.isOnline && this.$workbox && this.$workbox.active instanceof Promise) {
        const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/
        return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
          if (value !== uniqueValue) {
            // @TODO
          }
        }, () => {
          console.log('offline')
        })
      } */


      this.listLoading = true;
      (0, _goodReceivedNote.getList)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    async fetchSupplier(queryString, cb) {
      let keyword = queryString;

      if (queryString === this.supplierSelectedName) {
        keyword = null;
      }

      (0, _lodash.default)(search => {
        const params = {
          perPage: 120,
          search
        };

        if (this.listQuery.status !== 'all') {
          params.goodReceivedStatus = this.listQuery.status;

          if (this.listQuery.status !== 'Completed') {
            params.hash = this.hash;
          }
        }

        (0, _supplier.getSuppliers)(params).then(response => cb(response.data.map(supplier => ({
          label: supplier.name,
          value: supplier.uuid
        }))));
      }, 630)(keyword);
    },

    adjustTabs() {
      this.$nextTick(() => {
        this.$refs.tabs.calcPaneInstances(true);
      });
    },

    clearSupplier() {
      this.listQuery.supplierId = null;
      this.listQuery.suppliers = null;
      this.supplierSelectedName = null;
    },

    checkSupplier() {
      if (this.supplierSelectedName !== this.supplierName) {
        this.supplierName = this.supplierSelectedName;
      }
    },

    handleSelectSupplier(item) {
      this.listQuery.supplierId = item.value;
      this.listQuery.suppliers = item.value;
      this.supplierSelectedName = item.label;
    },

    editGoodReceivedNote(id) {
      this.$router.push(`/good-received-notes/edit/${id}`);
    },

    addGoodReceivedNote() {
      this.$router.push(`/good-received-notes/add`);
    },

    downloadGoodReceivedNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNote)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

    downloadGoodReceivedNoteTransitNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNoteTransitNote)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}_transit_note.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

    deleteGoodReceivedNote(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('GoodReceivedNotes.ConfirmDelete'), 'Confirm', {
        confirmButtonText: _i18n.default.t('GoodReceivedNotes.Delete'),
        cancelButtonText: _i18n.default.t('GoodReceivedNotes.Cancel'),
        type: 'warning'
      }).then(() => (0, _goodReceivedNote.deleteGoodReceivedNote)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('GoodReceivedNotes.Deleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    async handleTabClick(tab) {
      if (this.activeName === tab.name) {
        return this.$router.push(`/good-received-notes/` + tab.name);
      } else {
        this.activeName = tab.name; // on Created will visit this

        this.fetchData(tab.name); // this.activeName = tab.name
        // return
      }
    }

  }
};
exports.default = _default;