var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.goodReceivedNote,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID", prop: "displayId" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.goodReceivedNote.displayId,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodReceivedNote, "displayId", $$v)
                  },
                  expression: "goodReceivedNote.displayId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GoodReceivedNotes.Status"),
                prop: "status",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  value: _vm.goodReceivedNote.status
                    ? _vm.$t("GoodReceivedNotes." + _vm.goodReceivedNote.status)
                    : "",
                  disabled: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("GoodReceivedNotes.Type"), prop: "type" },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.goodReceivedNote.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodReceivedNote, "type", $$v)
                  },
                  expression: "goodReceivedNote.type",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("route.Supplier"), prop: "supplierId" } },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.suppliers,
                  label: "name",
                  reduce: function (supplier) {
                    return supplier.uuid
                  },
                  disabled: _vm.confirmed || _vm.completed,
                  clearable: false,
                },
                model: {
                  value: _vm.goodReceivedNote.supplierId,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodReceivedNote, "supplierId", $$v)
                  },
                  expression: "goodReceivedNote.supplierId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GoodReceivedNotes.PONumber"),
                prop: "poNum",
              },
            },
            [
              _vm.goodReceivedNote && _vm.goodReceivedNote.purchaseOrder
                ? _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/purchase-orders/edit/" +
                              _vm.goodReceivedNote.purchaseOrder.uuid,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.goodReceivedNote.purchaseOrder.displayId)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GoodReceivedNotes.GoodReceivedDate"),
                prop: "goodReceivedDate",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  disabled: _vm.confirmed || _vm.completed,
                  clearable: false,
                },
                model: {
                  value: _vm.goodReceivedNote.goodReceivedDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodReceivedNote, "goodReceivedDate", $$v)
                  },
                  expression: "goodReceivedNote.goodReceivedDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.goodReceivedNote.supplierId
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("GoodReceivedNotes.Items") } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.goodReceivedNote.items.filter(function (
                          item
                        ) {
                          return item.showItemQty
                        }),
                      },
                    },
                    [
                      _vm.confirmed || _vm.completed
                        ? _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            data: scope.row.transferNotes,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: _vm.$t(
                                                "GoodReceivedNotes.Warehouse"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope2) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope2.row.warehouse
                                                              .name
                                                          )
                                                        ),
                                                      ]),
                                                      _c("br"),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: _vm.$t(
                                                "GoodReceivedNotes.WarehouseArea"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope2) {
                                                    return [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "Warehouse.Area"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              scope2.row
                                                                .warehouseAreaId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope2.row,
                                                                "warehouseAreaId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope2.row.warehouseAreaId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.getWarehouseAreasForWarehouseRawMaterial(
                                                            scope.row
                                                              .rawMaterial.uuid,
                                                            scope2.row.warehouse
                                                              .uuid
                                                          ),
                                                          function (area) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: area.name,
                                                                attrs: {
                                                                  label:
                                                                    area.name,
                                                                  value:
                                                                    area.uuid,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      scope2.row.qty > 0 &&
                                                      !scope2.row
                                                        .warehouseAreaId
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "color-danger",
                                                            },
                                                            [_vm._v("Required")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: _vm.$t(
                                                "GoodReceivedNotes.RequestedQty"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope2) {
                                                    return [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            scope2.row
                                                              .requestedQty
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: _vm.$t(
                                                "GoodReceivedNotes.OutstandingQty"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope2) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          class: {
                                                            "color-danger":
                                                              scope2.row
                                                                .requestedQty -
                                                                scope2.row
                                                                  .transferredQty -
                                                                (_vm.completed
                                                                  ? 0
                                                                  : scope2.row
                                                                      .qty) >
                                                              0,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              parseFloat(
                                                                (
                                                                  scope2.row
                                                                    .requestedQty -
                                                                  scope2.row
                                                                    .transferredQty -
                                                                  (_vm.completed
                                                                    ? 0
                                                                    : scope2.row
                                                                        .qty)
                                                                ).toFixed(2)
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: _vm.$t(
                                                "GoodReceivedNotes.ReceivedQty"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope2) {
                                                    return [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          precision: 2,
                                                          min: 0,
                                                          step: _vm.calculateRoundStep(
                                                            scope2.row.qty,
                                                            scope.row.qty -
                                                              _vm.transferedTotal(
                                                                scope.row.id
                                                              ) +
                                                              scope2.row.qty
                                                          ),
                                                          size: "small",
                                                          max:
                                                            scope.row.qty -
                                                            _vm.transferedTotal(
                                                              scope.row.id
                                                            ) +
                                                            scope2.row.qty,
                                                          disabled:
                                                            !_vm.confirmed,
                                                        },
                                                        model: {
                                                          value: scope2.row.qty,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope2.row,
                                                              "qty",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope2.row.qty",
                                                        },
                                                      }),
                                                      _c("br"),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4077153824
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("GoodReceivedNotes.Name") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.showItemQty
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(scope.row.rawMaterial.sku)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            scope.row.rawMaterial.nameEng
                                              ? scope.row.rawMaterial.nameEng
                                              : scope.row.rawMaterial.nameChin
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GoodReceivedNotes.Qty"),
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.showItemQty
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            disabled:
                                              _vm.confirmed || _vm.completed,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("GoodReceivedNotes.POQty")
                                            ) +
                                              ": " +
                                              _vm._s(scope.row.poQty) +
                                              " " +
                                              _vm._s(
                                                _vm.pluralize(
                                                  scope.row.rawMaterial.unit,
                                                  scope.row.poQty
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            disabled:
                                              _vm.confirmed || _vm.completed,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "GoodReceivedNotes.POReceived"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                scope.row.poReceivedQTY +
                                                  scope.row.poCanceledQTY
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.pluralize(
                                                  scope.row.rawMaterial.unit,
                                                  scope.row.poReceivedQTY +
                                                    scope.row.poCanceledQTY
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            disabled:
                                              _vm.confirmed || _vm.completed,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "GoodReceivedNotes.POOutstanding"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                scope.row.poQty -
                                                  (scope.row.poReceivedQTY +
                                                    scope.row.poCanceledQTY)
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.pluralize(
                                                  scope.row.rawMaterial.unit,
                                                  scope.row.poQty -
                                                    (scope.row.poReceivedQTY +
                                                      scope.row.poCanceledQTY)
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm.drafted
                                        ? _c(
                                            "span",
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  precision: 2,
                                                  min: 0,
                                                  step: 0.01,
                                                  max:
                                                    scope.row.poQty -
                                                    (scope.row.poReceivedQTY +
                                                      scope.row.poCanceledQTY),
                                                  size: "small",
                                                  disabled:
                                                    _vm.confirmed ||
                                                    _vm.completed,
                                                },
                                                model: {
                                                  value: scope.row.qty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "qty",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.qty",
                                                },
                                              }),
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.pluralize(
                                                      scope.row.rawMaterial
                                                        .unit,
                                                      scope.row.qty
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.confirmed || _vm.completed
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "GoodReceivedNotes.Received"
                                                )
                                              ) +
                                                ": " +
                                                _vm._s(scope.row.qty) +
                                                " " +
                                                _vm._s(
                                                  _vm.pluralize(
                                                    scope.row.rawMaterial.unit,
                                                    scope.row.qty
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.confirmed
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "GoodReceivedNotes.Allocated"
                                                )
                                              ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.transferedTotal(
                                                    scope.row.id
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.pluralize(
                                                    scope.row.rawMaterial.unit,
                                                    _vm.transferedTotal(
                                                      scope.row.id
                                                    )
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.confirmed
                                        ? _c(
                                            "span",
                                            {
                                              class: {
                                                "color-danger":
                                                  scope.row.qty * 100 -
                                                    _vm.transferedTotal(
                                                      scope.row.id
                                                    ) *
                                                      100 !==
                                                  0,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "GoodReceivedNotes.PendingAllocation"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    parseFloat(
                                                      (
                                                        (scope.row.qty * 100 -
                                                          _vm.transferedTotal(
                                                            scope.row.id
                                                          ) *
                                                            100) /
                                                        100
                                                      ).toFixed(2)
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.pluralize(
                                                      scope.row.rawMaterial
                                                        .unit,
                                                      (scope.row.qty * 100 -
                                                        _vm.transferedTotal(
                                                          scope.row.id
                                                        ) *
                                                          100) /
                                                        100
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("Order.Price"), width: "180" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            $" +
                                      _vm._s(
                                        scope.row.unitPrice
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2542015035
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GoodReceivedNotes.Remark"),
                          width: "180",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.showItemQty
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(scope.row.remark) +
                                          "\n          "
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GoodReceivedNotes.Operations"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.showItemQty
                                  ? [
                                      !(_vm.confirmed || _vm.completed)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Order.Delete"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("Order.Total")) +
                      ": " +
                      _vm._s(_vm.total) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("GoodReceivedNotes.Remark") } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  disabled: _vm.confirmed || _vm.completed,
                },
                model: {
                  value: _vm.goodReceivedNote.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodReceivedNote, "remark", $$v)
                  },
                  expression: "goodReceivedNote.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.confirmed && _vm.recievedtype
                ? [
                    _c("el-checkbox", {
                      attrs: { label: "Confirm Allocated" },
                      model: {
                        value: _vm.confirmed_allocated,
                        callback: function ($$v) {
                          _vm.confirmed_allocated = $$v
                        },
                        expression: "confirmed_allocated",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.createMode
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.onSubmit("Drafted")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _vm.goodReceivedNote.purchaseOrder
                      ? _c("el-button", { on: { click: _vm.onBackToPO } }, [
                          _vm._v(_vm._s(_vm.$t("GoodReceivedNotes.BackToPO"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.goodReceivedNote.purchaseOrder
                      ? _c("el-button", { on: { click: _vm.onCancel } }, [
                          _vm._v(
                            _vm._s(_vm.$t("GoodReceivedNotes.BackToList"))
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.drafted && !_vm.createMode && _vm.recievedtype
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-circle-check",
                                },
                                on: { click: _vm.confirmGoodReceivedNote },
                              },
                              [_vm._v(_vm._s(_vm.buttonConfirm))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.deleteGoodReceivedNote },
                              },
                              [_vm._v(_vm._s(_vm.buttonDelete))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadGoodReceivedNote(
                                      _vm.goodReceivedNote.uuid,
                                      _vm.goodReceivedNote.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { display: "none" },
                                attrs: {
                                  icon: "el-icon-document-copy",
                                  type: "info",
                                },
                                on: { click: _vm.generateText },
                              },
                              [_vm._v(_vm._s(_vm.$t("GoodReceivedNotes.Copy")))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onBackToPO } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToPO")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onCancel } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToList")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.confirmed
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-success",
                                },
                                on: { click: _vm.completeGoodReceivedNote },
                              },
                              [_vm._v(_vm._s(_vm.buttonComplete))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-download",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadGoodReceivedNote(
                                      _vm.goodReceivedNote.uuid,
                                      _vm.goodReceivedNote.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { display: "none" },
                                attrs: {
                                  icon: "el-icon-document-copy",
                                  type: "info",
                                },
                                on: { click: _vm.generateText },
                              },
                              [_vm._v("Copy")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onBackToPO } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToPO")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onCancel } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToList")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.drafted && _vm.canceledtype
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onBackToPO } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToPO")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onCancel } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToList")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.deleteGoodReceivedNote },
                              },
                              [_vm._v(_vm._s(_vm.buttonDelete))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.completed
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadGoodReceivedNote(
                                      _vm.goodReceivedNote.uuid,
                                      _vm.goodReceivedNote.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadGoodReceivedNoteTransitNote(
                                      _vm.goodReceivedNote.uuid,
                                      _vm.goodReceivedNote.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownloadTransitNote))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { display: "none" },
                                attrs: {
                                  type: "info",
                                  icon: "el-icon-document-copy",
                                },
                                on: { click: _vm.generateText },
                              },
                              [_vm._v("Copy")]
                            ),
                            _vm._v(" "),
                            _vm.canceledtype
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-delete",
                                    },
                                    on: { click: _vm.deleteGoodReceivedNote },
                                  },
                                  [_vm._v(_vm._s(_vm.buttonDelete))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onBackToPO } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToPO")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.goodReceivedNote.purchaseOrder
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.onCancel } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GoodReceivedNotes.BackToList")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }