var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.addTitle, visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.newLeavehistory
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.newLeavehistory,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Leave type", prop: "leave" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "Please select..." },
                      on: { change: _vm.changeUnit },
                      model: {
                        value: _vm.history.leave_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.history, "leave_id", $$v)
                        },
                        expression: "history.leave_id",
                      },
                    },
                    _vm._l(_vm.userLeaves, function (userLeave) {
                      return _c("el-option", {
                        key: userLeave.leave.id,
                        attrs: {
                          label: userLeave.leave.name,
                          value: userLeave.leave.uuid,
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gary-text",
                      staticStyle: { "margin-left": "10px" },
                    },
                    [_vm._v(_vm._s(_vm.remainHolidayDisplay))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "開始時間", prop: "timefrom" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex font" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          clearable: false,
                          disabled: _vm.ot_count === 1,
                        },
                        on: { change: _vm.calcTotal },
                        model: {
                          value: _vm.history.datefrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.history, "datefrom", $$v)
                          },
                          expression: "history.datefrom",
                        },
                      }),
                      _vm._v(" "),
                      _vm.leaveUnit === "hour"
                        ? _c("el-time-select", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              "picker-options": {
                                start: "00:00",
                                step: "00:30",
                                end: "23:59",
                              },
                              clearable: false,
                              placeholder: "請選擇時間",
                            },
                            on: { change: _vm.calcTotal },
                            model: {
                              value: _vm.history.timefrom,
                              callback: function ($$v) {
                                _vm.$set(_vm.history, "timefrom", $$v)
                              },
                              expression: "history.timefrom",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.leaveUnit === "half-day"
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              on: { change: _vm.calcTotal },
                              model: {
                                value: _vm.history.periodfrom,
                                callback: function ($$v) {
                                  _vm.$set(_vm.history, "periodfrom", $$v)
                                },
                                expression: "history.periodfrom",
                              },
                            },
                            _vm._l(_vm.periods, function (period) {
                              return _c("el-option", {
                                key: period.value,
                                attrs: {
                                  label: period.name,
                                  value: period.value,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "結束時間", prop: "timeto" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex font" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "picker-options": _vm.dateToOptions,
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          clearable: false,
                          disabled: _vm.ot_count === 1,
                        },
                        on: { change: _vm.calcTotal },
                        model: {
                          value: _vm.history.dateto,
                          callback: function ($$v) {
                            _vm.$set(_vm.history, "dateto", $$v)
                          },
                          expression: "history.dateto",
                        },
                      }),
                      _vm._v(" "),
                      _vm.leaveUnit === "hour"
                        ? _c("el-time-select", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              "picker-options": {
                                start: _vm.timetoStart,
                                step: "00:30",
                                end: "23:59",
                              },
                              clearable: false,
                              placeholder: "請選擇時間",
                            },
                            on: { change: _vm.calcTotal },
                            model: {
                              value: _vm.history.timeto,
                              callback: function ($$v) {
                                _vm.$set(_vm.history, "timeto", $$v)
                              },
                              expression: "history.timeto",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.leaveUnit === "half-day"
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              on: { change: _vm.calcTotal },
                              model: {
                                value: _vm.history.periodto,
                                callback: function ($$v) {
                                  _vm.$set(_vm.history, "periodto", $$v)
                                },
                                expression: "history.periodto",
                              },
                            },
                            _vm._l(_vm.periods, function (period) {
                              return _c("el-option", {
                                key: period.value,
                                attrs: {
                                  label: period.name,
                                  value: period.value,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.leaveUnitDisplay, prop: "total" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      step: _vm.leaveUnitStep,
                      min: _vm.leaveMin,
                      max: _vm.leaveMax,
                      readonly: "",
                      required: "",
                    },
                    model: {
                      value: _vm.history.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.history, "total", $$v)
                      },
                      expression: "history.total",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "請假原因" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.history.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.history, "remark", $$v)
                      },
                      expression: "history.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Order.Status"), prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "Please select..." },
                      model: {
                        value: _vm.history.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.history, "status", $$v)
                        },
                        expression: "history.status",
                      },
                    },
                    _vm._l(
                      _vm.roles.find(function (role) {
                        return ["Operation Manager", "Admin"].includes(
                          role.name
                        )
                      })
                        ? _vm.history_statuses.filter(function (status) {
                            return status.value !== "pending"
                          })
                        : _vm.history_statuses.filter(function (status) {
                            return status.value === "pending"
                          }),
                      function (status) {
                        return _c("el-option", {
                          key: status.value,
                          attrs: { label: status.name, value: status.value },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Attachment", prop: "attachment" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        name: "attachment[]",
                        action:
                          _vm.uploadDomain +
                          "/api/v1/leave-history-attachment/upload",
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        "on-success": _vm.handleSuccess,
                        multiple: "",
                        drag: "",
                        limit: 3,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList,
                        headers: _vm.uploadHeader,
                        data: _vm.uploadData,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("Drop file here or "),
                        _c("em", [_vm._v("click to upload")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("jpg/png files with a size less than 500kb")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.isOffline,
                        icon: "el-icon-success",
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }